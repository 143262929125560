
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { toFormValidator } from "@vee-validate/zod";
import { z } from "zod";
import { useField, useForm } from "vee-validate";
import { auth } from "@/firebase";

interface ResetPasswordValues {
  email?: string;
}

export default defineComponent({
  name: "ResetPasswordForm",
  emits: ["resetSuccess", "resetError"],
  setup(props, context) {
    // Grab emit from context and get a router object.
    const { emit } = context;

    const { t } = useI18n();

    // Zod schema validation.
    const validationSchema = toFormValidator(
      z.object({
        email: z
          .string()
          .nonempty(t("login.email.error.empty"))
          .email({ message: t("login.email.error.wrong") }),
      })
    );

    const { handleSubmit, errors, resetForm, isSubmitting } = useForm({
      validationSchema,
    });

    const { value: email } = useField("email");

    const onSubmit = handleSubmit(async (values: ResetPasswordValues) => {
      const { email } = values;

      // If the values are somehow undefined we return.
      if (!email) return;

      try {
        await auth.sendPasswordResetEmail(email);
        resetForm();

        emit("resetSuccess");
      } catch (e) {
        if (e?.code !== "auth/user-not-found") emit("resetError");
        emit("resetSuccess");
      }
    });

    return {
      validationSchema,
      email,
      errors,
      onSubmit,
      isSubmitting,
    };
  },
  methods: {
    backToLogin: async function () {
      await this.$router.push({
        name: "Login",
        params: {
          locale: this.$i18n.locale,
        },
      });
    },
  },
});
