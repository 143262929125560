
import { defineComponent } from "vue";
import ResetPasswordForm from "@/components/ResetPasswordForm.vue";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "ResetPassword",
  components: { ResetPasswordForm },
  methods: {
    showErrorNotification() {
      const errorMessage = this.$t("general.messages.error.something");
      ElMessage({
        type: "error",
        message: errorMessage,
      });
    },
    showSuccessNotification() {
      const successMessage = this.$t("login.reset.success");
      ElMessage({
        type: "success",
        duration: 0,
        showClose: true,
        message: successMessage,
      });
    },
  },
});
